<template>
  <span
    class="chip chip-sm bg-gray02 font-small"
    :class="chipClass"
  >
    {{ label }}
  </span>
</template>

<script>
export default {
  props: {
    chipClass: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  }
};
</script>
