<template>
	<img
		class="img-fit w-100 h-100"
		v-if="industryTagName"
		:src="industryTagImage"
	/>
</template>

<script>
export default {
	data() {
		return {
      // HACK: 要リファクタリング keyを文字列からidに変えたい
      // 現状MultiSelectionのコンポーネントから渡ってくる値がnameのみのためidが使用できない
      // そのためそちらのリファクタも必要となる
			industryTagIcon: {
        '教育': 'biz_education.png',
        '医療・ヘルスケア': 'biz_healthcare.png',
        '金融': 'biz_finance.png',
        '官公庁・行政': 'biz_public.png',
        '運輸・交通': 'biz_transportation.png',
        '建設・不動産': 'biz_realestate.png',
        'エネルギー': 'biz_energy.png',
        '食品・飲料': 'biz_food.png',
        'IT・通信': 'biz_it.png',
        '流通・小売': 'biz_retail.png',
        '旅行・観光': 'biz_travel.png',
        '機械・プラント': 'biz_factory.png',
        '広告・メディア': 'biz_media.png',
        '自動車': 'biz_automotive.png',
        '商社': 'biz_trading.png',
        '電機・精密機器': 'biz_electronics.png',
        'スポーツ・フィットネス': 'biz_fitness.png',
        '化学': 'biz_chemistry.png'
      }
		}
	},
	props: {
		industryTagName: {
      type: String,
      default: ""
    },
	},
	computed: {
		industryTagImage() {
      if (this.industryTagName) {
        return require(`../../../../assets/images/industry_tag_icon/${this.industryTagIcon[this.industryTagName]}`)
      }
    },
	}
}
</script>
