<template>
  <div
    class="bg-pale-blue"
  >
    <div
      ref="photoWindow"
      class="photo-window d-flex align-items-center"
      :style="{ 'height': photoWindowHeight }"
    >
      <img
        id="photo"
        :src="photoData ? photoData : defaultImage"
        alt="Company Photo"
        class="d-block image-card-photo"
      >
    </div>
    <div
      v-if="selfExpression"
      id="self-expression"
      class="px-4 py-md-6 py-4"
    >
      <p class="self-expression-title mb-1">
        {{ t('become_for_customers') }}
      </p>
      <span class="self-expression-content bold preview-text">
        {{ selfExpression }}
      </span>
    </div>
  </div>
</template>

<script>
import "../../i18n/ja";
import { i18n } from "../../mixins/i18n";

export default {
  mixins: [i18n],
  props: {
    photo: {
      type: String,
      default: ""
    },
    selfExpression: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      photoData: this.photo,
      defaultImage: require("../../../../assets/images/default_banner.png"),
      innerWidth: window.innerWidth,
      photoWindowHeight: 0
    };
  },
  watch: {
    innerWidth() {
      this.calcWindowHeight();
    }
  },
  mounted() {
    window.addEventListener("resize", () => (this.innerWidth = window.innerWidth));
    this.calcWindowHeight();
    this.$emit("mount", this.photoData);
  },
  beforeDestroy() {
    window.removeEventListener("resize");
  },
  methods: {
    calcWindowHeight() {
      const widthRatio = this.$refs.photoWindow.clientWidth / 45;

      if (widthRatio === 0) {
        this.photoWindowHeight = this.innerWidth > 768 ? "320px" : `${this.innerWidth * 0.32}px`;
      } else {
        this.photoWindowHeight = `${widthRatio * 16}px`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.image-card-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .image-card {
    width: 100%;
  }
}
.self-expression-title {
  font-size: 1rem;
  @media (max-width: 767px) {
    font-size: 0.85715rem;
  }
}
.self-expression-content {
  font-size: 2rem;
  @media (max-width: 767px) {
    font-size: 1.71428rem;
  }
}
</style>
