<template>
  <div
    class="card case-study-card text-black"
  >
    <div
      class="card-body p-4 p-md-6"
    >
      <div class="case-study-card-content border-bottom mb-4" data-mh="case-study-content">
        <div class="d-flex">
          <div class="industry-tag-icon mr-3 mb-xl-3 mb-4">
            <!-- # HACK: 要リファクタリング -->
            <industry-tag-icon :industry-tag-name="industryTag.toString()"/>
          </div>
          <div class="flex-1">
            <span class="industry-tag-chip d-inline-block mb-1 px-3 font-small">
              {{ industryTag.toString() }}
            </span>
            <div class="public-name font-weight-bold mb-xl-3 mb-4">
              {{ publicName }}
            </div>
          </div>
        </div>
        <div
          class="tags d-flex"
        >
          <!-- ↓PCサイズ時に発生するアイコン下部分のスペースのため↓ -->
          <div class="icon-bottom-space mr-3 d-xl-block d-none"/>
          <inline-tags-list class="flex-1" :tags-array="tags" />
        </div>
      </div>
      
      <div class="d-flex align-items-start">
        <div
          class="logo mr-1 d-inline-block"
        >
          <img
            v-if="companyLogo === ''"
            :src="defaultImage"
            class="img-fit w-100 h-100"
          />
          <img
            v-else
            :src="companyLogo"
            class="img-fit w-100 h-100"
          />
        </div>
        <span class="company-name">
          {{ companyName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import InlineTagsList from "../companies/InlineTagsList.vue";
import industryTagIcon from "./industryTagIcon.vue";

export default {
  components: {
    InlineTagsList,
    industryTagIcon
  },
  props: {
    industryTag: {
      type: Array,
      default() {
        return [];
      }
    },
    publicName: {
      type: String,
      default: ""
    },
    tags: {
      type: Array,
      default() {
        return [];
      }
    },
    companyName: {
      type: String,
      default: ""
    },
    companyLogo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      defaultImage: require("../../../../assets/images/no_logo.png")
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  transition: 0.5s;
  border: none;

  &:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

    img {
      opacity: 1;
    }
  }

  .card-title {
    width: 100%;
    border-radius: 3px 3px 0 0;
  }

  .card-body {
    // 事例詳細ページ内企業詳細 事例カードの「実施内容」背景色
    .logo {
      width: 56px;
      height: 36px;
    }
    .industry-tag-icon {
      width: 70px;
      height: 70px;
      @media (min-width: 1200px) {
        width: 80px;
        height: 80px;
      }
    }
    .icon-bottom-space {
      width: 80px;
    }
    .chip {
      cursor: pointer !important;
    }
    .industry-tag-chip {
      color: #33b88b;
      padding-top: 3px;
      padding-bottom: 3px;
      border: 1px solid #33b88b;
      border-radius: 30px;
    }
    .public-name {
      font-size: 16px;
      @media (min-width: 1200px) {
        font-size: 18px;
      }
    }
    .tags {
      padding-bottom: 11px;
    }
    .company-name {
      flex: 1;
      padding-top: 6px;
      line-height: 22.5px;
    }
  }
}
</style>
