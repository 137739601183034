<template>
  <div :class="setClass">
    <p
      v-if="title !== ''"
    >
      {{ title }}
    </p>
    <v-tag
      v-for="tag in tagsArray"
      :key="tag"
      :label="tag"
      :chip-class="chipClass"
    />
  </div>
</template>

<script>
import VTag from "./VTag.vue";

export default {
  components: {
    VTag
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    setClass: {
      type: String,
      default: "btn-group d-block"
    },
    tagsArray: {
      type: Array,
      default() {
        return [];
      }
    },
    chipClass: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
</style>
