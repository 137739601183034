<template>
  <div class="card bg-white mb-4 mb-md-6 position-relative">
    <div class="company-photo-wrapper">
      <company-photo
        :photo="photo"
      />
      <p class="white-text bold px-4 py-2 mb-0 w-100 company-expression">
        {{ selfExpression }}
      </p>
    </div>
    <div>
      <div class="company-profile-box p-4 bg-white" data-mh="company-profile">
        <div class="d-flex align-items-start">
          <div class="mr-1 company-logo">
            <img
              :src="logo === '' ? defaultLogo : logo"
              class="img-fit w-100 h-100"
            >
          </div>
          <div class="flex-1 mb-0 d-inline-block align-self-center">
            <h3 class="mb-0 bold company-name">
              {{ companyName }}
            </h3>
            <p
              v-if="businessCategories"
              class="business-categories text-dark-gray font-small mb-0"
            >
              {{ businessCategories.join('、 ') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyPhoto from "../companies/CompanyPhoto.vue";

export default {
  components: { CompanyPhoto },
  props: {
    businessCategories: {
      type: Array,
      default() {
        return [];
      }
    },
    companyName: {
      type: String,
      default: ""
    },
    photo: {
      type: String,
      default: ""
    },
    selfExpression: {
      type: String,
      default: ""
    },
    logo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      defaultLogo: require("../../../../assets/images/no_logo.png")
    };
  }
};
</script>

<style scoped lang="scss">
.card {
  transition: 0.5s;
  border: none;
  border-radius: 3px !important;
  &:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    img {
      opacity: 1;
    }
  }
}
.company-photo-wrapper {
  position: relative;
  .company-expression {
    position: absolute;
    bottom: 0;
    min-height:80px;
    background: linear-gradient(rgba(95, 105, 118, 0), rgba(40, 40, 40, .5));
    font-size: 1rem;
    display: flex;
    align-items: flex-end;
  }
}
.company-name {
  font-size: 1.142rem;
}
.company-logo {
  width: 56px;
  height: 36px;
  display: inline-block;
}
@media (max-width: 1199px) {
  .company-photo-wrapper {
    .company-expression {
      font-size: 0.85715rem;
    }
  }
  .company-name {
    font-size: 1rem;
  }
}
</style>
