// webpacker.ymlのresolved_pathsに指定したプレフィックスは省略可能

// Vue.js
import Vue from "vue/dist/vue.esm";

// /components/case_studies
import CaseStudyCard from "components/case_studies/CaseStudyCard.vue";
import CompanyIndexCard from "components/companies/CompanyIndexCard";

export default new Vue({
  el: "#vue-app",
  components: {
    CaseStudyCard,
    CompanyIndexCard
  },

  // HACK: cardにh-100付与で代替できそう
  mounted() {
    $(".case-study-card").matchHeight();
    $(".card-title").matchHeight();
  }
});
